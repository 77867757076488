/**
 * Home functionality
 * @param {jQuery} $container
 */

import Vue from 'vue/dist/vue';
import Vuelidate from 'vuelidate';
import SlideUpDown from 'vue-slide-up-down';
import {required} from 'vuelidate/lib/validators';
import slotmachines from '../config/slotmachines';
import Typed from "typed.js";
import VueTippy, {TippyComponent} from "vue-tippy";

require('./slotmachine');

const VueInputMask = require('vue-inputmask').default;

// Custom validations
const valid_postal_code = function(value, vm) {
    let re = /^[1-9][0-9]{3}[ ]?([A-RT-Za-rt-z][A-Za-z]|[sS][BCbcE-Re-rT-Zt-z])$/;
    return re.test(value);
};
const valid_date        = function(value, vm) {
    let valueSplit = value.split('-');
    let timestamp  = Date.parse(valueSplit[2] + '/' + valueSplit[1] + '/' + valueSplit[0]);
    return !isNaN(timestamp) && timestamp < new Date();
};
const valid_mobile      = function(value, vm) {
    value = value.replace(/_/g, ""); // Remove underscores added by the mask
    return value.indexOf('06-') === 0 && value.length === 11;
};
// Validate email
const valid_email       = function(value, vm) {
    return /^(.*)+@(.*)$/.test(value);
};

Vue.use(Vuelidate);
Vue.use(VueInputMask);
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);
Vue.component('slide-up-down', SlideUpDown);

const initializeContainer = function($container) {
    // Initialize typing effect
    if ($('.typed').length) {
        new Typed('.typed', {
            stringsElement: '.typed-strings',
            startDelay:     1000,
            typeSpeed:      75,
            backSpeed:      75,
            onComplete:     function() {
                $('.typed, .typed-cursor').addClass('done');
            }
        });
    }

    let id = '.retention-check';
    if (!$(id).length) {
        return;
    }
    let vm = new Vue({
        el:          id,
        data:        {
            // State
            desktopWidth:         990,
            step:                 1,
            initialized:          false,
            loadingText:          '',
            loadingTextDefault:   '',
            loadingTextWaiting:   '',
            showLoadingScreen:    false,
            showLoadingTextTimer: false,
            showResults:          false,
            isStore:              $(id).data('is-store'),
            recaptchaSiteKey:     $(id).data('recaptcha-sitekey'),
            returnResult:         {
                text: $(id).data('return-text'),
                url:  $(id).data('return-url'),
            },
            czcUrl:               $(id).data('czc-url'),

            // Provider config
            providersCta:  $(id).data('providers-cta'),
            providers:     {
                'TMobile':        'T-Mobile',
                'Vodafone':       'Vodafone',
                'Telfort':        'Telfort',
                'KPN':            'KPN',
                'Ben':            'Ben',
                'Tele2':          'Tele2',
                'hollandsnieuwe': 'Hollandsnieuwe',
                'Simyo':          'Simyo',
                'Simpel':         'Simpel',
                'Youfone':        'Youfone',
                'Lebara':         'Lebara',
            },
            providersNoAA: [
                'hollandsnieuwe',
                'Lebara',
                'Simpel',
                'Simyo',
                'Tele2',
                'Youfone',
            ],

            // Slots config
            slots:     [],
            nrOfSlots: 8,

            // Input fields
            errors:       [],
            showErrors:   false,
            submitStatus: null,

            // Business inputs
            store:         $(id).data('selected-store'),
            business:      '',
            email:         '',
            conditions:    false,
            msisdn:        '06-',
            date_birth:    {
                day:   '01',
                month: '01',
                year:  '1970',
            },
            postal_code:   null,
            street_number: null,
            duration:      12,
            date_start:    {
                day:   '01',
                month: '01',
                year:  new Date().getFullYear() - 2,
            },

            // Results
            endpoint:                     $(id).data('action'),
            checkFailedMsg:               $(id).data('check-failed'),
            checkRetries:                 0,
            checkMaxRetries:              5,
            checkRetrySpeed:              15000,
            checkRetryTimeout:            null,
            resultError:                  false,
            resultErrors:                 [],
            resultProvider:               '',
            resultSubscriptionDuration:   'XX-XX-XXXX',
            resultDateStart:              'XX-XX-XXXX',
            resultDateRenewable:          'XX-XX-XXXX',
            resultIntentId:               null,
            resultRetainable:             false,
            resultRetainableWithHardware: false,
        },
        created:     function() {
            this.initialized = true;
        },
        mounted:     function() {
            this.initSlotmachines();

            let container = $(id);

            // Prefill provider if exists and is in providers array
            if (container.data('provider') && $(id).data('provider') in this.providers) {
                this.business = $(id).data('provider');
            }

            // Prefill msisdn if exists
            if (container.data('msisdn')) {
                this.msisdn = $(id).data('msisdn');
            }

            // Set loading messages
            this.loadingText        = container.data('loading-1');
            this.loadingTextDefault = container.data('loading-1');
            this.loadingTextWaiting = container.data('loading-2');
            this.loadingTextRetry   = container.data('loading-retry');
        },
        watch:       {
            business:          function() {
                if (this.business !== null) {
                    this.nextStep(2);
                }
            },
            showLoadingScreen: function() {
                this.loadingText = this.loadingTextDefault;
            },
        },
        validations: {
            msisdn:        {
                required,
                valid_mobile
            },
            postal_code:   {
                required,
                valid_postal_code
            },
            street_number: {
                required,
            },
            email:         {
                required,
                valid_email
            },
            conditions:    {
                required
            }
        },
        methods:     {
            /**
             * Scroll to specified selector
             *
             * @param {string} selector
             * @param {number} offset
             */
            scrollTo: function(selector, offset) {
                // If no offset is given, set offset based mobile/desktop and header
                let $header = $('header');
                if (typeof offset === 'undefined') {
                    if (window.outerWidth < this.desktopWidth) {
                        offset = 14;
                    } else {
                        offset = 0;
                        if ($header.hasClass('ms-glue--fixed')) {
                            offset = 44;
                        }
                    }
                }

                if (typeof selector === 'undefined') {
                    // Scroll to top
                    $('html, body').animate({scrollTop: 0}, 'normal');
                } else {
                    // Scroll to element
                    let navbar   = $header.outerHeight();
                    let $element = $(selector);
                    if ($element.length) {
                        let position = $(selector).offset().top - navbar - offset;
                        $('html, body').animate({scrollTop: position}, 'normal');
                    }
                }
            },

            /**
             * Determine if the current business or the passed business is AA
             *
             * @param id
             * @returns {boolean}
             */
            businessIsAA: function(id) {
                if (typeof id !== 'undefined' && id !== '') {
                    return this.providersNoAA.indexOf(id) > -1;
                } else if (this.businessId !== '') {
                    return this.providersNoAA.indexOf(this.businessId) > -1;
                }
                return false;
            },

            /**
             * Set the active step for the brand selector
             *
             * @param step
             */
            nextStep: function(step) {
                if (typeof step !== 'undefined') {
                    this.step = step;
                } else {
                    this.step += 1;
                }

                if (this.step === 2 && this.$v.$dirty && this.$v.$invalid) {
                    // Don't scroll to the next step if form is invalid
                } else {
                    this.scrollTo($container.find('.retention-step-' + this.step));
                }
            },

            /**
             * Set up the date as a slotmachine to be used later
             *
             * @param date Date without hyphens like DDMMYYYY
             */
            initSlotmachines: function(date) {
                // Initialize date-slotmachine
                let slotConfig = slotmachines.getSlotmachinesConfig(date);
                for (let i = 1; i <= this.nrOfSlots; i++) {
                    if (typeof this.slots[i] === 'object') {
                        this.slots[i].destroy();
                    }
                    this.slots[i] = new SlotMachine(document.querySelector('.slot-' + i), slotConfig[i]);
                }
            },

            /**
             * Shuffle the date
             */
            dateShuffle: function() {
                this.slots.forEach(function(slot) {
                    slot.shuffle(9999);
                });
                setTimeout(() => this.dateStop(), 3000);
            },

            /**
             * Stop each date-slot sequentially
             */
            dateStop: function() {
                let delay = 0;
                this.slots.forEach(function(slot) {
                    setTimeout(() => slot.stop(), delay);
                    delay += 500;
                });
                // Show table with results and banner
                setTimeout(() => this.showData(), 5000);
            },

            /**
             * Process the form
             */
            processForm: function() {
                let _this        = this;
                this.showErrors  = true;
                this.showResults = false;

                this.$v.$touch();
                if (!this.conditions || this.$v.$invalid) {
                    // Error in the form, errors are displayed in the view. Scroll to the first one
                    setTimeout(function() {
                        _this.scrollTo('.error:first', 80);
                    });
                } else {
                    this.showLoadingScreen = true;
                    this.resultError       = false;
                    let formData           = $(id).find('.retainable-form').serialize();
                    let submitRetention    = function(submitData) {
                        $.ajax({
                            url:      _this.endpoint,
                            method:   'post',
                            dataType: 'json',
                            data:     submitData,
                            cache:    false,
                            success:  function(result) {
                                _this.resultErrors = [];
                                _this.resultError  = false;

                                if (result.status === 'success') {
                                    switch (result.data.status) {
                                        case 'complete':
                                            // Check completed
                                            _this.checkProcess(result);
                                            break;
                                        case 'queued':
                                            // Check is queued, keep retrying
                                            clearTimeout(_this.checkRetryTimeout);
                                            _this.checkRetries      = 0;
                                            _this.checkRetryTimeout = setTimeout(function() {
                                                _this.checkRetry(result);
                                            }, _this.checkRetrySpeed);
                                            break;
                                        default:
                                            result.messages.forEach(function(message) {
                                                _this.resultErrors.push(message);
                                            });
                                            _this.showLoadingScreen = false;
                                            _this.resultError       = true;
                                            _this.nextStep(2);
                                            break;
                                    }
                                } else {
                                    // Check failed, show warning
                                    result.messages.forEach(function(message) {
                                        _this.resultErrors.push(message);
                                    });
                                    if (_this.resultErrors.length === 0) {
                                        _this.resultErrors.push(_this.checkFailedMsg);
                                    }
                                    _this.showLoadingScreen = false;
                                    _this.resultError       = true;
                                    _this.nextStep(2);
                                }
                            },
                            error:    function() {
                                _this.resultErrors      = [];
                                _this.resultError       = true;
                                _this.showLoadingScreen = false;
                                _this.nextStep(2);
                            },
                        });
                    };
                    if (_this.recaptchaSiteKey && window.grecaptcha) {
                        // Fetch recaptcha token and submit it with the form,
                        // NOTE: use reCAPTCHA V3 for this!
                        grecaptcha.ready(function() {
                            grecaptcha
                                .execute(_this.recaptchaSiteKey, {action: 'retention_check'})
                                .then(function(token) {
                                    formData += '&recaptcha=' + token;
                                    submitRetention(formData);
                                });
                        });
                    } else {
                        submitRetention(formData);
                    }
                }
            },

            /**
             * If check was successful, process and show the data
             *
             * @param result
             */
            checkProcess: function(result) {
                let _this = this;

                let isRetainable = null;
                if (result.data.contract.is_retainable !== null) {
                    isRetainable = result.data.contract.is_retainable;
                }

                let isRetainableWithHardware = null;
                if (result.data.contract.retainable_with_hardware !== null) {
                    isRetainableWithHardware = result.data.contract.retainable_with_hardware;
                }

                let dateStart = null;
                if (result.data.contract.date_start !== null) {
                    dateStart = result.data.contract.date_start.split('T')[0].split('-');
                }

                let dateEnd = null;
                if (result.data.contract.date_end !== null) {
                    dateEnd = result.data.contract.date_end.split('T')[0].split('-');
                }

                let dateRenewable = null;
                if (result.data.contract.date_retainable !== null) {
                    dateRenewable = result.data.contract.date_retainable.split('T')[0].split('-');
                }

                this.nextStep(3);
                this.resultProvider               = this.providers[this.business];
                this.resultDateStart              = (dateStart !== null ? dateStart[2] + '-' + dateStart[1] + '-' + dateStart[0] : '-');
                this.resultSubscriptionDuration   = (dateEnd !== null ? dateEnd[2] + '-' + dateEnd[1] + '-' + dateEnd[0] : '-');
                this.resultDateRenewable          = (dateRenewable !== null ? dateRenewable[2] + '-' + dateRenewable[1] + '-' + dateRenewable[0] : '-');
                this.resultIntentId               = result.data.id;
                this.resultRetainable             = isRetainable;
                this.resultRetainableWithHardware = isRetainableWithHardware;

                // Remove hyphens to correctly set the values for the slotmachines and shuffle them
                setTimeout(function() {
                    _this.initSlotmachines(_this.resultDateRenewable.replace(/-/g, ""));
                    if (_this.retainableDateAvailable && !_this.resultRetainableWithHardware) {
                        // If retainable date is known and isn't so2hs, show slotmachines
                        _this.dateShuffle();
                    } else {
                        // Only show result
                        _this.showData();
                    }
                }, 250);
                this.showLoadingScreen = false;

            },

            /**
             * If check failed or queued, retry again
             *
             * @param result
             */
            checkRetry: function(result) {
                let _this = this;
                $.ajax({
                    url:      _this.endpoint,
                    method:   'get',
                    dataType: 'json',
                    data:     {
                        id: result.data.id
                    },
                    cache:    false,
                    success:  function(result) {
                        switch (result.data.status) {
                            case 'success':
                                // Succeeded, pass data to process
                                _this.checkProcess(result);
                                _this.showLoadingScreen = false;
                                _this.nextStep(3);
                                break;
                            case 'queued':
                                // Still queued, try again after 30 seconds
                                if (_this.checkRetries < _this.checkMaxRetries) {
                                    clearTimeout(_this.checkRetryTimeout);
                                    _this.checkRetries++;
                                    _this.loadingText       = _this.loadingTextWaiting + ' ' + _this.loadingTextRetry.replace('%count%', _this.checkRetries).replace('%max%', _this.checkMaxRetries);
                                    _this.checkRetryTimeout = setTimeout(function() {
                                        _this.checkRetry(result);
                                    }, _this.checkRetrySpeed);
                                } else {
                                    clearTimeout(_this.checkRetryTimeout);
                                    _this.showError(null);
                                    _this.nextStep(2);
                                }
                                break;
                            case 'failed':
                                // Failed, show error
                                clearTimeout(_this.checkRetryTimeout);
                                _this.showError(result.data.exception_message_human === null || result.data.exception_message_human === 'De T-Mobile backend heeft een (tijdelijk) probleem' ? null : result.data.exception_message_human);
                                _this.nextStep(2);
                                break;
                        }
                    },
                    error:    function() {
                        clearTimeout(_this.checkRetryTimeout);
                        _this.resultErrors.push(null);
                        _this.resultError = true;
                    },
                });
            },

            /**
             * Show error message and hide loading screen
             *
             * @param msg
             */
            showError: function(msg = this.checkFailedMsg) {
                if (msg !== null) {
                    this.resultErrors.push(msg);
                }
                this.resultError       = true;
                this.showLoadingScreen = false;
            },

            /**
             * Show final results (table + banner)
             */
            showData: function() {
                this.showResults = true;
            },
        },
        computed:    {
            /**
             * Determines current step for the brand/type search
             *
             * @returns {string}
             */
            stepClass() {
                let loading = (this.showLoadingScreen ? ' loading' : '');
                return 'step' + this.step + loading;
            },
            businessId() {
                return this.business !== null && this.business !== '' && this.providersCta[this.business] ? this.providers[this.business] : '';
            },
            ctaPhone() {
                return this.business !== null && this.business !== '' && this.providersCta[this.business] ? this.providersCta[this.business]['phone'] : '';
            },
            ctaSim() {
                return this.business !== null && this.business !== '' && this.providersCta[this.business] ? this.providersCta[this.business]['sim'] : '';
            },
            ctaSimImage() {
                return this.business;
            },
            retainableDateAvailable() {
                return this.resultDateRenewable !== null && this.resultDateRenewable !== '-';
            },
            czcUrlWithProvider() {
                return this.czcUrl + '?provider=' + this.providers[this.business];
            },
        },
    });
};

export default {
    initializeContainer: initializeContainer,
    initialize:          function() {
        $(document).ready(function() {
            let $page = $('.page-retentionchecker');
            initializeContainer($page);
        });
    },
};
