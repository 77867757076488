/*
 * Include styling files
 */
require('./style/main.scss');

/*
 * Include scripts
 */
import ui from './script/ui';
import retentionCheck from './script/components/retention-checker';

// Initialize the UI
ui.initialize();
retentionCheck.initialize();
