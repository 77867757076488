export default {
    getSlotmachinesConfig: function(string = '00000000') {
        return {
            1: {
                active:    10,
                spins:     3,
                delay:     100,
                randomize: function() {
                    return string.charAt(0);
                }
            },
            2: {
                active:    10,
                spins:     3,
                delay:     300,
                randomize: function() {
                    return string.charAt(1);
                }
            },
            3: {
                active:    10,
                spins:     3,
                delay:     200,
                randomize: function() {
                    return string.charAt(2);
                }
            },
            4: {
                active:    10,
                spins:     3,
                delay:     100,
                randomize: function() {
                    return string.charAt(3);
                }
            },
            5: {
                active:    10,
                spins:     3,
                delay:     300,
                randomize: function() {
                    return string.charAt(4);
                }
            },
            6: {
                active:    10,
                spins:     3,
                delay:     225,
                randomize: function() {
                    return string.charAt(5);
                }
            },
            7: {
                active:    10,
                spins:     3,
                delay:     100,
                randomize: function() {
                    return string.charAt(6);
                }
            },
            8: {
                active:    10,
                spins:     3,
                delay:     260,
                randomize: function() {
                    return string.charAt(7);
                },
            },
        };
    },
};
