/**
 * Initialize polyfills
 */
const initialize = function() {
    // Add object.remove() if missing
    if (typeof Element.prototype.remove === 'undefined') {
        Element.prototype.remove = function() {
            this.parentElement.removeChild(this);
        }
    }
};

// Export initialization
export default {
    initialize: initialize,
};
