/**
 * Global UI
 */

import polyfills from './components/polyfills';

window.Popper = require('popper.js/dist/umd/popper.min');
require('bootstrap/dist/js/bootstrap.bundle');
require('./../../../../js/vendor/lazysizes/lazysizes');

/**
 * Initialize a single container for UI purposes
 * @param {jQuery} $container
 */
const initializeContainer = function($container) {
    // Setup tooltips
    $('body').tooltip({
        selector: '[data-toggle="tooltip"]'
    });
};

// Export initialization
export default {
    initializeContainer: initializeContainer,
    initialize:          function() {
        // One time initialization
        $(document).ready(function() {
            initializeContainer($('body'));
            polyfills.initialize();
        });
    }
};
